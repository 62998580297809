export default {
    _: {
        width: 1024,
        height: 1024,
        paths: [
            {
                d: "M324.962,829.766c-174.452,-102.475 -233.68,-330.352 -131.204,-504.804c102.475,-174.452 330.352,-233.68 504.804,-131.204c54.198,31.836 99.368,77.006 131.204,131.204c4.595,7.821 13.014,12.64 22.084,12.64l103.64,-0c14.05,-0 25.612,-11.562 25.612,-25.612c0,-3.625 -0.77,-7.209 -2.258,-10.514c-115.359,-256.234 -421.135,-372.156 -677.368,-256.796c-256.234,115.36 -372.156,421.135 -256.796,677.369c51.35,114.056 142.739,205.446 256.796,256.795c12.811,5.768 28.1,-0.028 33.868,-12.839c1.488,-3.306 2.258,-6.89 2.258,-10.515l-0,-103.64c-0,-9.07 -4.819,-17.489 -12.64,-22.084Z",
            },
            {
                d: "M865.432,616.35c-26.527,89.703 -86.213,166.038 -166.87,213.416c-7.821,4.595 -12.639,13.014 -12.639,22.084l-0,103.64c-0,14.05 11.561,25.612 25.611,25.612c3.625,0 7.209,-0.77 10.515,-2.258c199.443,-89.792 320.367,-297.869 299.646,-515.61c-1.245,-13.078 -12.36,-23.185 -25.497,-23.185l-720.732,-0c-12.207,-0 -22.8,8.727 -25.138,20.707c-6.572,33.686 -6.572,68.326 0,102.012c2.338,11.981 12.931,20.707 25.138,20.707l138.971,0c14.05,0 25.612,11.562 25.612,25.612l-0,387.111c-0,13.137 10.107,24.252 23.185,25.497c32.279,3.072 64.777,3.072 97.056,-0c13.078,-1.245 23.185,-12.36 23.185,-25.497l0,-387.111c0,-14.05 11.562,-25.612 25.612,-25.612l231.785,0c14.05,0 25.611,11.562 25.611,25.612c0,2.459 -0.354,4.905 -1.051,7.263Z",
            },
        ],
    },
}
