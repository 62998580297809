<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import RegisterForm from "./auth/FormRegister.svelte"
    import Header from "./Header.svelte"

    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import { getContext } from "svelte"
    import lang from "$lang"
    let modalVC = getContext<IModalVC>("ModalVC")

    export let email: string = ""
    export let password: string = ""
</script>

<ModalFullscreen>
    <Header
        slot="head"
        title={$lang.menu.Registration()}
        backText={$lang.Cancel()}
        escBack={false}
    />

    <div class="card-p">
        <RegisterForm {email} {password} onSuccess={() => modalVC.pop()} />
    </div>
</ModalFullscreen>
