<script lang="ts">
    import ModalFormIssue from "$components/issue/ModalFormIssue.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Button from "$components/tui/Button.svelte"
    import * as env from "$env/static/public"
    import grouptube from "$lib/icons/grouptube"
    import { auth } from "$lib/model/Auth"
    import { chat_quote_fill, list } from "@timephy/tui-icons-svelte"
    import posthog from "posthog-js"
    import { getContext } from "svelte"
    import { Icon } from "svelte-awesome"
    import SettingsMain from "../settings/SettingsMain.svelte"
    import UserImage from "../user/UserImage.svelte"
    import Notification from "$components/Notification.svelte"
    import { BehaviorSubject } from "rxjs"
    import LanguageSelection from "$components/LanguageSelection.svelte"
    import lang from "$lang"

    const modalVC = getContext<IModalVC>("ModalVC")

    const { accountData$, signedIn$ } = auth

    export let hidden = false

    export let onHome: (() => void) | null = null
    export let showLeft: boolean = true
    export let fromWithinGroup = false

    /* ========================================================================================== */

    function clickMenu() {
        posthog.capture("open menu")

        modalVC.push({
            component: SettingsMain,
            props: { fromWithinGroup },
        })
    }

    function clickHome(event: Event) {
        posthog.capture("click home")

        if (onHome) {
            event.preventDefault()
            onHome()
            return
        }
    }

    /* ========================================================================================== */

    function onSendFeedback() {
        modalVC.push({
            component: ModalFormIssue,
            props: {
                type: undefined,
            },
        })
    }
</script>

<div
    style:display={hidden ? "none" : ""}
    class="flex h-[70px] w-full shrink-0 items-center justify-between gap-6 border-b border-step-050 bg-step-000 mt-safe pl-safe-or-6 pr-safe-or-4"
>
    <!-- ! Logo -> Home -->
    <div class="flex items-center gap-4">
        <a href="/" class="flex items-center gap-4 text-3xl font-medium" on:click={clickHome}>
            <div class="relative">
                <Icon data={grouptube} class="size-10" />
                {#if env.PUBLIC_ENV}
                    <p class="absolute left-6 top-0 rounded-md bg-step-150 px-1 font-mono text-xs">
                        {env.PUBLIC_ENV}
                    </p>
                {/if}
            </div>
            <p class="hidden sm:block">GroupTube</p>
        </a>

        {#if showLeft}
            <!-- !! Feedback prompt -->
            {#if $signedIn$}
                <div class="hidden shrink-0 lg:block">
                    <Button
                        thin
                        color="gray"
                        class="px-[12px]"
                        icon={chat_quote_fill}
                        on:click={onSendFeedback}
                    >
                        {$lang.Send_Feedback()}
                    </Button>
                </div>
            {/if}
        {/if}
    </div>

    <!-- ! Center -->
    <slot name="center" />

    <!-- ! Right -->
    <div class="flex items-center gap-4">
        <slot>
            <LanguageSelection />
        </slot>
        <Button
            thin
            color="transparent"
            class="relative !p-1 !pr-[10px]"
            on:click={clickMenu}
            ariaLabel="Account and Settings Menu"
        >
            {#if $accountData$}
                <div class="hidden md:block">
                    <UserImage size="tiny" user={$accountData$} />
                </div>
            {:else}
                <div class="pl-2 text-step-600">Login</div>
            {/if}
            <Icon data={list} class="size-6 text-step-600" />
            {#if fromWithinGroup && !$accountData$}
                <Notification
                    value$={new BehaviorSubject({ color: "red", content: "" })}
                    class="absolute right-0 top-0"
                />
            {/if}
        </Button>
    </div>
</div>
