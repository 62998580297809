<script lang="ts">
    import api from "$lib/api"
    import { auth } from "$lib/model/Auth"
    import { SCHEMA } from "$lib/validate"
    import { Subject, debounceTime, switchMap } from "rxjs"
    import {
        setError,
        stringProxy,
        superForm,
        superValidateSync,
    } from "sveltekit-superforms/client"
    import { z } from "zod"

    import Image from "$components/Image.svelte"
    import SubLink from "$components/SubLink.svelte"
    import ModalImageSelection from "$components/modal/ModalImageSelection.svelte"
    import Button from "$components/tui/Button.svelte"
    import TuiContent from "$components/tui/TuiContent.svelte"
    import TuiContentSection from "$components/tui/TuiContentSection.svelte"
    import Input from "$components/tui/Input/Input.svelte"
    import posthog from "posthog-js"
    import lang from "$lang"

    /* ========================================= Exports ======================================== */

    export let onSuccess: (() => void) | null = null

    /* ========================================== Form ========================================== */

    const formSchema = z.object({
        ...SCHEMA.account,
    })

    // Using `superValidateSync` instead of `superValidate` because we are top-level and cannot run `await`
    const formValidate = superValidateSync(
        // Initial field values
        {
            username: auth.accountData$.value?.username ?? "",
            name: auth.accountData$.value?.name ?? "",
            image: auth.accountData$.value?.image ?? "",
        },
        // The Zod object schema
        formSchema,
        // Options
        {
            // Add initial errors to all fields that are not validated successfully
            errors: false,
            // Needs to be specified if there are ever two forms with the same schema
            id: undefined,
        },
    )

    const {
        // A store of the form data
        form,
        // A store of all validation errors of fields
        errors,
        // A store listing the constraints for each field, can be applied to `<input>` elements via `{...$constraints.field}`
        constraints,
        // A store listing the tainted (dirty) form fields
        tainted,
        // A directive to improve the `<form>` element (required for SPA mode)
        enhance,
    } = superForm(formValidate, {
        // Use SPA mode (use `validators` instead of sending to server + run `onUpdate` on success)
        SPA: true,
        // Best is `auto` (it validates on input when there was an error and validates on blur otherwise)
        validationMethod: "auto",
        // For client side validation (Zod object schema), if omitted no client side validation is performed
        validators: formSchema,
        // Reset the form upon a successful result
        resetForm: false, // changed
        // Do not trigger load functions
        invalidateAll: false,
        // Allow any data in the form, not only strings or numbers
        dataType: "json",

        // Because we are in SPA mode, we use `onUpdate`
        onUpdate: async ({ form }) => {
            // Check that all fields have been validated
            if (form.valid) {
                console.log("[Form] submit()")

                // Seems like this is not (/ no longer) necessary
                // Blur all input elements to validate them
                // ;(document.activeElement as HTMLElement | null)?.blur()

                // Check conditions not checked by `validators`
                let validUsername = await SCHEMA.account.username.safeParseAsync(form.data.username)
                if (!validUsername.success) {
                    setError(
                        form,
                        "username",
                        validUsername.error.issues.map((issue) => issue.message),
                    )

                    return
                }

                // Do submit action
                const result = await auth.updateAccountProfile({
                    username: form.data.username,
                    name: form.data.name,
                    image: form.data.image,
                })

                // Handle form submit errors
                if (!result.ok) {
                    if (result.error.type === "Unauthenticated") {
                        setError(form, "Not signed in properly")
                    } else if (result.error.type === "UsernameTaken") {
                        setError(form, "username", "Username already used")
                    } else if (result.error.type === "AccountIdNotFound") {
                        setError(form, "Account does not exist")
                    } else if (result.error.type === "Check") {
                        setError(form, result.error.content)
                    } else if (result.error.type === "Internal") {
                        setError(form, "Internal Server Error")
                    } else if (result.error.type === "Network") {
                        setError(form, "Network Error")
                    } else if (result.error.type === "InvalidResponse") {
                        setError(form, "Unknown Error")
                    }

                    return
                }

                // Successfully submitted
                if (onSuccess) onSuccess()

                posthog.capture("account profile updated")
            }
        },
    })

    /* ================================ Username Free Validation ================================ */

    const username$ = new Subject<string>()
    stringProxy(form, "username", { empty: "null" }).subscribe((username) =>
        username$.next(username),
    )
    username$
        .pipe(
            debounceTime(500),
            // filter((username) => schema.username.safeParse(username).success),
            switchMap(async (username) => {
                let parsed = SCHEMA.account.username.safeParse(username)
                if (!parsed.success) {
                    return parsed.error.issues.map((issue) => issue.message)
                }

                if (username !== auth.accountData$.value?.username) {
                    let result = await api.account.is_username_free({ username })
                    if (result.ok) {
                        if (!result.value) {
                            return ["Username already used"]
                        }
                    } else {
                        if (result.error.type === "Internal") {
                            return ["Internal Server Error"]
                        } else if (result.error.type === "Network") {
                            return ["Network Error"]
                        } else if (result.error.type === "InvalidResponse") {
                            return ["Unknown Error"]
                        }
                    }
                }
            }),
        )
        .subscribe((errorArray) => {
            $errors.username = errorArray
        })

    /* ====================================== Image Select ====================================== */

    let userImages: string[] = []
    api.assets.images_user().then((images) => {
        if (images.ok) {
            userImages = images.value

            // Set random user image if required
            if (!$form.image) {
                const image = userImages[Math.floor(Math.random() * userImages.length)]
                $form.image = image
            }
        }
    })

    function selectImage(selection: string) {
        console.log("selectImage", selection)
        $form.image = selection
    }
</script>

<form method="POST" use:enhance>
    <TuiContent>
        <TuiContentSection>
            <h2>{$lang.register.Your_Profile()}</h2>

            <!-- ! Name -->
            <Input
                name="name"
                placeholder="Steve Craft"
                bind:value={$form.name}
                invalid={!!$errors.name}
                autocomplete="given-name"
                {...$constraints.name}
            />
            {#each $errors.name ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <!-- ! Username -->
            <Input
                name="username"
                prefix="@"
                placeholder="steve_craft"
                bind:value={$form.username}
                invalid={!!$errors.username}
                autocomplete="nickname"
                {...$constraints.username}
            />
            {#each $errors.username ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <!-- ! Image -->
            <SubLink
                class="py-1 pl-1"
                component={ModalImageSelection}
                props={{
                    cols: 3,
                    colsLg: 5,
                    roundedFull: true,
                    images: userImages,
                    select: selectImage,
                    selected: $form.image,
                }}
            >
                <div class="flex items-center gap-4">
                    <div
                        class="h-[60px] w-[60px] shrink-0 overflow-hidden rounded-full border border-step-150"
                    >
                        <Image image={$form.image} size={128} />
                    </div>
                    <p class="text-step-600">{$lang.Select_Image()}</p>
                </div>
            </SubLink>

            <p>{$lang.register.Profile_Confidence()}</p>
        </TuiContentSection>

        <!-- ! General Errors -->
        {#if $errors._errors}
            <TuiContentSection>
                {#each $errors._errors as error}
                    <p class="error">{error}</p>
                {/each}
            </TuiContentSection>
        {/if}

        <!-- ! Submit Button -->
        <TuiContentSection>
            <Button color="blue" type="submit" disabled={!$tainted}>{$lang.Save()}</Button>
        </TuiContentSection>
    </TuiContent>
</form>
