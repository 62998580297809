<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import FormResetPasswordRequest from "./auth/FormResetPasswordRequest.svelte"
    import Header from "./Header.svelte"
    import lang from "$lang"

    import ModalOk from "$components/modal/ModalOk.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import { getContext } from "svelte"
    let modalVC = getContext<IModalVC>("ModalVC")

    export let email: string = ""
</script>

<ModalFullscreen>
    <Header slot="head" title="Forgot Password" backText={$lang.Cancel()} escBack={false} />

    <div class="card-p">
        <FormResetPasswordRequest
            {email}
            onSuccess={() => {
                modalVC.pop()
                modalVC.push({
                    component: ModalOk,
                    props: {
                        title: "Email sent",
                        text: "You have been sent an email to reset your password.",
                    },
                })
            }}
            onCancel={() => modalVC.pop()}
        />
    </div>
</ModalFullscreen>
