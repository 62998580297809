<script lang="ts">
    import { z } from "zod"

    import api from "$lib/api"
    import { SCHEMA } from "$lib/validate"
    import { setError, superForm, superValidateSync } from "sveltekit-superforms/client"

    import Button from "$components/tui/Button.svelte"
    import TuiContent from "$components/tui/TuiContent.svelte"
    import TuiContentSection from "$components/tui/TuiContentSection.svelte"
    import Input from "$components/tui/Input/Input.svelte"
    import lang from "$lang"

    /* ========================================= Exports ======================================== */

    export let onSuccess: (() => void) | null = null
    export let onCancel: (() => void) | null = null

    export let email: string = ""

    /* ========================================== Form ========================================== */

    const formSchema = z.object({
        email: SCHEMA.login.email,
    })

    // Using `superValidateSync` instead of `superValidate` because we are top-level and cannot run `await`
    const formValidate = superValidateSync(
        // Initial field values
        {
            email,
        },
        // The Zod object schema
        formSchema,
        // Options
        {
            // Add initial errors to all fields that are not validated successfully
            errors: false,
            // Needs to be specified if there are ever two forms with the same schema
            id: undefined,
        },
    )

    const {
        // A store of the form data
        form,
        // A store of all validation errors of fields
        errors,
        // A store listing the constraints for each field, can be applied to `<input>` elements via `{...$constraints.field}`
        constraints,
        // A store listing the tainted (dirty) form fields
        tainted,
        // A directive to improve the `<form>` element (required for SPA mode)
        enhance,
    } = superForm(formValidate, {
        // Use SPA mode (use `validators` instead of sending to server + run `onUpdate` on success)
        SPA: true,
        // Best is `auto` (it validates on input when there was an error and validates on blur otherwise)
        validationMethod: "auto",
        // For client side validation (Zod object schema), if omitted no client side validation is performed
        validators: formSchema,
        // Reset the form upon a successful result
        resetForm: true,
        // Do not trigger load functions
        invalidateAll: false,
        // Allow any data in the form, not only strings or numbers
        dataType: "json",

        // Because we are in SPA mode, we use `onUpdate`
        onUpdate: async ({ form }) => {
            // Check that all fields have been validated
            if (form.valid) {
                console.log("[Form] submit()")

                // Seems like this is not (/ no longer) necessary
                // Blur all input elements to validate them
                // ;(document.activeElement as HTMLElement | null)?.blur()

                // Check conditions not checked by `validators`
                // if (!acceptLegal) {
                //     setError(form, "Accept Terms of Use and Privacy Policy")
                //
                //     return
                // }

                // Do submit action
                const result = await api.auth.reset_password_request({
                    email: form.data.email,
                })

                // Handle form submit errors
                if (!result.ok) {
                    if (result.error.type === "EmailNotFound") {
                        setError(form, "email", "Email address not found")
                    } else if (result.error.type === "Internal") {
                        setError(form, "Internal Server Error")
                    } else if (result.error.type === "Network") {
                        setError(form, "Network Error")
                    } else if (result.error.type === "InvalidResponse") {
                        setError(form, "Unknown Error")
                    }

                    return
                }

                // Successfully submitted
                if (onSuccess) onSuccess()
            }
        },
    })
</script>

<form method="POST" use:enhance>
    <TuiContent>
        <TuiContentSection>
            <h2>Forgot Password</h2>
            <p>
                Enter the email address you registered with. We will send you an email to reset your
                password.
            </p>

            <!-- ! Email -->
            <Input
                name="email"
                type="email"
                placeholder="steve@gmail.com"
                bind:value={$form.email}
                invalid={!!$errors.email}
                autocomplete="username"
                {...$constraints.email}
            />
            {#each $errors.email ?? [] as error}
                <p class="error">{error}</p>
            {/each}
        </TuiContentSection>

        <!-- ! General Errors -->
        {#if $errors._errors}
            <TuiContentSection>
                {#each $errors._errors as error}
                    <p class="error">{error}</p>
                {/each}
            </TuiContentSection>
        {/if}

        <!-- ! Submit Button -->
        <TuiContentSection>
            <div class="grid grid-cols-2 gap-2">
                <Button color="gray" on:click={onCancel}>
                    {$lang.Cancel()}
                </Button>
                <Button color="orange" type="submit">
                    {$lang.Reset()}
                </Button>
            </div>
        </TuiContentSection>
    </TuiContent>
</form>
