<script lang="ts">
    import type { IconType } from "svelte-awesome/components/Icon.svelte"

    import { Icon } from "svelte-awesome"

    let className = ""
    export { className as class }

    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Button from "$components/tui/Button.svelte"
    import { chevron_right } from "@timephy/tui-icons-svelte"
    import { SvelteComponent, getContext, type ComponentProps, type ComponentType } from "svelte"
    const modalVC = getContext<IModalVC>("ModalVC")

    // export let title: string
    type C = $$Generic<SvelteComponent>
    export let component: ComponentType<C>
    export let props: ComponentProps<C>

    export let text: string | null = null
    export let icon: IconType | null = null
    export let thin: boolean = false

    export let disabled = false
</script>

<Button
    {thin}
    color="gray"
    class="min-w-[0] max-w-full justify-between !gap-[14px] {className}"
    on:click={() => {
        modalVC.push({
            component,
            props,
        })
    }}
    {disabled}
>
    <!-- <div class="flex gap-3 items-center w-full bg-red-000 shrink"> -->
    {#if icon}
        <Icon data={icon} class="h-4 w-4 shrink-0 text-step-500" />
    {/if}
    {#if text}
        <p class="grow truncate text-left">{text}</p>
    {/if}
    <slot />
    <!-- </div> -->
    <Icon data={chevron_right} class="h-3 w-3 shrink-0 text-step-500" />
</Button>
