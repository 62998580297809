<script lang="ts">
    import Docs from "./Docs.svelte"
    import ModalFullscreen from "./modal/ModalFullscreen.svelte"
    import Header from "./settings/Header.svelte"

    /* ========================================================================================== */

    export let title: string = "Docs"
    export let data: string

    export let hidden = false
</script>

<ModalFullscreen class="lg:max-w-2xl">
    <Header slot="head" {title} escBack={!hidden} />

    <div class="p-6">
        <Docs html={data} />
    </div>
</ModalFullscreen>
