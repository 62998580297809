<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import Header from "./Header.svelte"

    import ModalOk from "$components/modal/ModalOk.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import { getContext } from "svelte"
    import ChangePasswordForm from "./auth/FormChangePassword.svelte"
    import lang from "$lang"

    let modalVC = getContext<IModalVC>("ModalVC")
</script>

<ModalFullscreen>
    <Header slot="head" title="Password" backText={$lang.Cancel()} escBack={false} />

    <div class="card-p">
        <ChangePasswordForm
            onSuccess={() => {
                modalVC.pop()
                modalVC.push({
                    component: ModalOk,
                    props: {
                        title: "Password changed",
                        text: "Your password has been changed.",
                    },
                })
            }}
            onCancel={() => modalVC.pop()}
        />
    </div>
</ModalFullscreen>
