<script lang="ts">
    import Switch from "./tui/Switch.svelte"

    let className = ""
    export { className as class }

    export let label: string
    export let value: boolean
    export let onChange: null | ((value: boolean) => void) = null
    export let disabled: boolean = false
</script>

<div class="flex items-center justify-between gap-4 {className}">
    <p class={disabled ? "opacity-50" : ""}>{label}</p>
    <Switch bind:value {onChange} {disabled} />
</div>
