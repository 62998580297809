<script lang="ts">
    import { chevron_down } from "@timephy/tui-icons-svelte"
    import { Icon } from "svelte-awesome"
    import type { IconType } from "svelte-awesome/components/Icon.svelte"

    let className = ""
    export { className as class }

    // ====================================================================================================

    export let icon: IconType | null = null

    export let value: unknown

    export let disabled: boolean = false
    export let invalid: boolean = false

    /** tiny mode, this is only a small nugget-button */
    export let tiny = false
</script>

<div class="relative {className}">
    {#if icon !== null}
        <Icon
            data={icon}
            class="pointer-events-none absolute top-[50%] h-4 w-4 translate-y-[-50%]
            {tiny ? 'left-2' : 'left-4'}
            {disabled ? 'opacity-50' : ''}"
        />
    {/if}

    <select
        class="btn w-full cursor-pointer appearance-none truncate
            {tiny ? 'text-center' : ''}
            {tiny ? 'btn-thin' : 'btn-tall'}
            {tiny ? 'pr-2.5' : 'pr-10'}
            {icon !== null ? (tiny ? 'pl-8' : 'pl-11') : tiny ? 'pl-1' : 'pl-4'}
            {value === null || value === undefined ? 'text-step-500' : ''}
            {invalid ? 'outline outline-1 outline-red-000' : ''}
        "
        bind:value
        on:click
        on:change
        {disabled}
        aria-invalid={invalid}
    >
        <slot />
    </select>

    {#if !tiny}
        <Icon
            data={chevron_down}
            class="pointer-events-none absolute right-4 top-[50%] h-3 w-3 translate-y-[-50%]
    {disabled ? 'opacity-50' : ''}"
        />
    {/if}
</div>
