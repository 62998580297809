<script lang="ts" context="module">
    export type Color = "red" | "blue"

    export interface NotificationParams {
        content?: string | number
        color?: Color
    }

    const cls: Record<Color, string> = {
        red: "bg-red-000",
        blue: "bg-blue-000",
    }
</script>

<script lang="ts">
    import type { Observable } from "rxjs"

    let className = ""
    export { className as class }

    export let value$: Observable<NotificationParams | null>
</script>

{#if $value$}
    {@const color = $value$.color ?? "red"}
    <p
        class="{cls[color]}
        h-4 min-w-[16px] rounded-full px-1 text-xs text-white outline outline-1 outline-step-150 {className}"
    >
        {$value$.content ?? ""}
    </p>
{/if}
