<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import SwitchLabel from "$components/SwitchLabel.svelte"
    import { showChatTabNotificationBadge$ } from "$lib/settings/local"
    import Header from "./Header.svelte"
    import lang from "$lang"

    // @ts-expect-error
    showChatTabNotificationBadge$.set = showChatTabNotificationBadge$.next

    export let hidden = false
</script>

<ModalFullscreen>
    <Header slot="head" title={$lang.menu.Chat()} escBack={!hidden} />

    <div class="settings">
        <div>
            <h2>Notifications</h2>
            <SwitchLabel
                label="Show Badge"
                bind:value={$showChatTabNotificationBadge$}
                class="px-[6px]"
            />
            <p>Whether to show a badge on the chat tab indicating a new unread message.</p>
        </div>
        <!-- <hr />
        <div>
            <h1>Info Log</h1>
            <p>Whether to log these events in chat.</p>
            <SwitchLabel label="Log GroupTube Events" value={false} class="px-[6px]" />
            <SwitchLabel label="Log User Join & Leave" value={true} class="px-[6px]" />
        </div> -->
    </div>
</ModalFullscreen>
