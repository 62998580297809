<script lang="ts">
    import { goto } from "$app/navigation"
    import ModalFormIssue from "$components/issue/ModalFormIssue.svelte"
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Button from "$components/tui/Button.svelte"
    import User from "$components/user/User.svelte"
    import { auth } from "$lib/model/Auth"
    import {
        bug_fill,
        chat_fill,
        ear_fill,
        eye_fill,
        patch_question_fill,
        question_circle_fill,
    } from "@timephy/tui-icons-svelte"
    import { getContext } from "svelte"
    import SubLink from "../SubLink.svelte"
    import Header from "./Header.svelte"
    import SettingsAccount from "./SettingsAccount.svelte"
    import SettingsAppearance from "./SettingsAppearance.svelte"
    import SettingsChat from "./SettingsChat.svelte"
    import SettingsLegal from "./SettingsLegal.svelte"
    import SettingsSounds from "./SettingsSounds.svelte"
    import SettingsTutorials from "./SettingsTutorials.svelte"
    import FormLogin from "./auth/FormLogin.svelte"
    import LanguageSelection from "$components/LanguageSelection.svelte"
    import lang from "$lang"

    const { auth$: authResult$, accountData$, accountPermissions$, signedIn$ } = auth

    let modalVC = getContext<IModalVC>("ModalVC")

    export let hidden = false
    /** If the menu is shows when connected to a group */
    export let fromWithinGroup = false

    /* ========================================================================================== */

    function onSendFeedback() {
        modalVC.push({
            component: ModalFormIssue,
            props: {
                type: undefined,
            },
        })
    }

    function onRequestSupport() {
        modalVC.push({
            component: ModalFormIssue,
            props: {
                type: "Help",
            },
        })
    }

    function onReportBug() {
        modalVC.push({
            component: ModalFormIssue,
            props: {
                type: "Bug",
            },
        })
    }
</script>

<ModalFullscreen>
    <Header slot="head" title={$lang.Settings()} escBack={!hidden}>
        <LanguageSelection class="justify-self-end" />
    </Header>

    <div class="settings">
        {#if $authResult$ === null || $accountData$ === null}
            <FormLogin {fromWithinGroup} />
        {:else}
            <div>
                <h1 class="text-xl font-semibold">{$lang.menu.Your_Account()}</h1>

                <SubLink class="py-1 pl-1" component={SettingsAccount} props={{}}>
                    <User
                        imageSize="normal"
                        user={{ type: "Account", ...$accountData$ }}
                        detail={"@" +
                            $accountData$.username +
                            ($authResult$.email_verified ? "" : " ⋅ " + $lang.Email_not_verified())}
                    />
                </SubLink>

                <p>{$lang.register.Profile_Confidence()}</p>
            </div>
        {/if}

        <hr />

        <div>
            <h1>{$lang.Preferences()}</h1>
            <!-- <p>Change how parts of GroupTube behave.</p> -->

            <div class="buttons-col">
                <SubLink
                    text={$lang.menu.Appearance()}
                    icon={eye_fill}
                    component={SettingsAppearance}
                    props={{}}
                />
                <SubLink
                    text={$lang.menu.Sounds()}
                    icon={ear_fill}
                    component={SettingsSounds}
                    props={{}}
                />
                <SubLink
                    text={$lang.menu.Chat()}
                    icon={chat_fill}
                    component={SettingsChat}
                    props={{}}
                />
                <SubLink
                    text={$lang.menu.Tutorials()}
                    icon={patch_question_fill}
                    component={SettingsTutorials}
                    props={{}}
                />
            </div>
        </div>

        {#if $signedIn$}
            <hr />

            <div>
                <div class="buttons-col">
                    <Button
                        thin
                        color="gray"
                        on:click={() => modalVC.push({ component: SettingsLegal, props: {} })}
                    >
                        Legal
                    </Button>
                    <!-- <SubLink thin text="Legal" component={SettingsLegal} props={{}} /> -->
                    <Button
                        thin
                        color="gray"
                        icon={question_circle_fill}
                        on:click={onRequestSupport}
                    >
                        Request Support
                    </Button>
                    <Button thin color="gray" icon={bug_fill} on:click={onReportBug}>
                        Report a Bug
                    </Button>
                </div>
            </div>
        {/if}

        {#if $accountPermissions$ !== null}
            <hr />

            <div>
                <!-- <h2>Admin Section</h2> -->
                <Button
                    thin
                    color="gray"
                    on:click={() => {
                        goto("/admin")
                        modalVC.popToRoot()
                    }}
                >
                    Admin Section
                </Button>
            </div>
        {/if}
    </div>
</ModalFullscreen>
