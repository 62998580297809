<script lang="ts">
    import Button from "$components/tui/Button.svelte"
    import type { Color } from "$components/tui/Button.svelte"
    import { escapeDownEvent$ } from "$lib/keys"
    import { getContext, onDestroy } from "svelte"
    import Modal from "./Modal.svelte"
    import type { IModalVC } from "./ModalVC.svelte"

    export let title: string
    export let text: string | null = null

    export let okText = "Ok"
    export let okColor: Color = "green"

    let modalVC = getContext<IModalVC>("ModalVC")

    let elem: HTMLButtonElement | null = null

    let subscriptionEscape = escapeDownEvent$.subscribe(() => {
        clickOk()
        // to stop pressing escape from focussing the button leading to this modal
        elem?.focus()
    })
    onDestroy(() => subscriptionEscape.unsubscribe())

    function clickOk() {
        modalVC.pop()
    }
</script>

<Modal>
    <div class="settings card max-w-xs bg-step-000">
        <div>
            <h1>{title}</h1>
            {#if text}
                <p class="whitespace-pre-line">{text}</p>
            {/if}
        </div>
        <div>
            <Button color={okColor} on:click={clickOk}>{okText}</Button>
            <!-- to stop pressing escape from focussing the button leading to this modal -->
            <button class="absolute" bind:this={elem}></button>
        </div>
    </div>
</Modal>
