<script lang="ts">
    let className = ""
    export { className as class }
</script>

<div class="flex flex-col gap-2 {className}">
    <slot />
</div>

<style lang="postcss">
    div {
        & > :global(h1),
        & > :global(h2),
        & > :global(h3),
        & > :global(p),
        & > :global(a) {
            @apply break-words px-[6px];
        }

        & > :global(h1) {
            @apply text-xl font-semibold;
        }
        & > :global(h2) {
            @apply text-lg font-medium;
        }
        & > :global(h3) {
            @apply text-base font-medium;
        }

        & > :global(p),
        & > :global(a) {
            @apply pb-1 text-xs text-step-500;
        }
        & > :global(p.error),
        & > :global(a.error) {
            @apply text-red-000;
        }

        & :global(a) {
            @apply underline;
        }
    }
</style>
