<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import SwitchLabel from "$components/SwitchLabel.svelte"
    import lang from "$lang"
    import { playSoundsGeneral$ } from "$lib/settings/local"
    import Header from "./Header.svelte"

    // @ts-expect-error
    playSoundsGeneral$.set = playSoundsGeneral$.next

    export let hidden = false
</script>

<ModalFullscreen>
    <Header slot="head" title={$lang.menu.Sounds()} escBack={!hidden} />

    <div class="settings">
        <div>
            <h2>General</h2>
            <SwitchLabel label="Play Sounds" bind:value={$playSoundsGeneral$} class="px-[6px]" />
            <p>
                Whether to play sounds on group events, like when a user joins or leaves the group.
            </p>
        </div>
    </div>
</ModalFullscreen>
