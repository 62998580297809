<script lang="ts">
    import api from "$lib/api"
    import { SCHEMA } from "$lib/validate"
    import { z } from "zod"

    import type { IssueType } from "$bindings/api/IssueType"
    import Input from "$components/tui/Input/Input.svelte"
    import TextArea from "$components/tui/TextArea.svelte"
    import ButtonIcon from "$components/tui/ButtonIcon.svelte"
    import SelectControl from "$components/tui/SelectControl.svelte"
    import { bytesToHumanString, zip } from "$lib/utils"
    import Button from "$components/tui/Button.svelte"
    import TuiContent from "$tui/TuiContent.svelte"
    import TuiContentSection from "$tui/TuiContentSection.svelte"
    import { cloud_arrow_up_fill, paperclip, trash3_fill } from "@timephy/tui-icons-svelte"
    import { setError, superForm, superValidateSync } from "sveltekit-superforms/client"
    import { IssueDescription, IssueIcon, IssuePrompt, IssueTitle, IssueTypes } from "./issue"

    /* ========================================= Exports ======================================== */

    export let type: z.infer<typeof SCHEMA.issue.type> | undefined = undefined

    export let onSuccess: ((type: IssueType) => void) | null = null

    /* ========================================================================================== */

    let uploading = false

    /* ========================================== Form ========================================== */

    const formSchema = z.object({
        type: SCHEMA.issue.type,
        title: SCHEMA.issue.title,
        content: SCHEMA.issue.content,
    })

    // Using `superValidateSync` instead of `superValidate` because we are top-level and cannot run `await`
    const formValidate = superValidateSync(
        // Initial field values
        {
            type,
        },
        // The Zod object schema
        formSchema,
        // Options
        {
            // Add initial errors to all fields that are not validated successfully
            errors: false,
            // Needs to be specified if there are ever two forms with the same schema
            id: undefined,
        },
    )

    const {
        // A store of the form data
        form,
        // A store of all validation errors of fields
        errors,
        // A store listing the constraints for each field, can be applied to `<input>` elements via `{...$constraints.field}`
        constraints,
        // A store listing the tainted (dirty) form fields
        tainted,
        // A directive to improve the `<form>` element (required for SPA mode)
        enhance,
    } = superForm(formValidate, {
        // Use SPA mode (use `validators` instead of sending to server + run `onUpdate` on success)
        SPA: true,
        // Best is `auto` (it validates on input when there was an error and validates on blur otherwise)
        validationMethod: "auto",
        // For client side validation (Zod object schema), if omitted no client side validation is performed
        validators: formSchema,
        // Reset the form upon a successful result
        resetForm: true,
        // Do not trigger load functions
        invalidateAll: false,
        // Allow any data in the form, not only strings or numbers
        dataType: "json",

        // Because we are in SPA mode, we use `onUpdate`
        onUpdate: async ({ form }) => {
            // Check that all fields have been validated
            if (form.valid) {
                console.log("[Form] submit()")

                // Seems like this is not (/ no longer) necessary
                // Blur all input elements to validate them
                // ;(document.activeElement as HTMLElement | null)?.blur()

                if (files.some((file) => file.size > FILE_MAX_SIZE)) {
                    setError(form, "File too large")
                    return
                }

                // Do submit action
                // Create DB Entry
                let result = await api.issue.create({
                    ...form.data,
                    object_descriptions: files.map((file) => {
                        return {
                            type: file.type,
                            size: file.size,
                            name: file.name,
                        }
                    }),
                })

                // Handle form submit errors
                if (!result.ok) {
                    if (result.error.type === "Check") {
                        setError(form, result.error.content)
                    } else if (result.error.type === "Internal") {
                        setError(form, "Internal Server Error")
                    } else if (result.error.type === "Network") {
                        setError(form, "Network Error")
                    } else if (result.error.type === "InvalidResponse") {
                        setError(form, "Unknown Error")
                    } else if (result.error.type === "Unauthenticated") {
                        setError(form, "Not signed in")
                    }
                    return
                }

                // Upload files to S3
                function readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader()

                        reader.onload = () => {
                            resolve(reader.result as ArrayBuffer)
                        }

                        reader.onerror = () => {
                            reader.abort()
                            reject(new DOMException("Problem parsing input file."))
                        }

                        reader.readAsArrayBuffer(file)
                    })
                }
                async function putObject(presignedUrl: string, file: File) {
                    console.info("putObject", file.name, presignedUrl)
                    // Read the file content
                    const buffer = await readFileAsArrayBuffer(file)

                    const response = await fetch(presignedUrl, {
                        method: "PUT",
                        headers: { "Content-Type": file.type },
                        body: buffer,
                    })
                    console.info("putObject response.ok", response.ok)
                }

                if (result.value.object_put_urls.length !== files.length) {
                    console.error(
                        "Received presigned put objects urls count is not the same as local files to upload.",
                    )
                    setError(form, "Files could not be uploaded")
                    return
                }

                if (files.length > 0) {
                    try {
                        uploading = true
                        for (const [file, presigned_url] of zip(
                            files,
                            result.value.object_put_urls,
                        )) {
                            await putObject(presigned_url, file)
                        }
                    } catch (error) {
                        setError(form, "Uploading files failed")
                    } finally {
                        uploading = false
                    }
                }

                // Successfully submitted
                if (onSuccess) onSuccess(form.data.type)
            }
        },
    })

    /* ===================================== Attached Files ===================================== */

    const FILE_MAX_SIZE = 64000000
    const FILE_MAX_SIZE_STRING = "64 MB"

    let files: File[] = []

    function addFile(file: File) {
        console.log("addFile", file)
        console.log(file.name, file.size, file.type, file.webkitRelativePath)

        files.push(file)
        files = files
    }

    function removeFile(idx: number) {
        console.log("removeFile", idx)
        files.splice(idx, 1)
        files = files
    }

    /* ========================================================================================== */

    function onAttachImage(event: Event) {
        console.log(event)

        const files = (event.target as HTMLInputElement)?.files ?? []
        for (const file of files) {
            addFile(file)
        }
    }

    function onDrop(event: DragEvent) {
        console.log("onDrop", event.dataTransfer)

        if (!event.dataTransfer) return

        const files = event.dataTransfer.files
        for (const file of files) {
            addFile(file)
        }
    }

    function onDragover(event: DragEvent) {
        clearTimeout(dragoverTimeout)
        dragover = true
        dragoverTimeout = setTimeout(() => {
            dragover = false
        }, 50)
    }

    let dragoverTimeout: ReturnType<typeof setTimeout> | undefined = undefined
    let dragover = false
</script>

<form
    method="POST"
    use:enhance
    on:drop|preventDefault={onDrop}
    on:dragover|preventDefault={onDragover}
>
    <TuiContent>
        <!-- ! Issue Type -->
        <TuiContentSection>
            <SelectControl
                bind:value={$form.type}
                icon={$form.type ? IssueIcon[$form.type] : null}
                invalid={!!$errors.type}
            >
                <option value={undefined} selected disabled>Select what kind of feedback</option>
                {#each IssueTypes as type}
                    <option value={type}>{IssueTitle[type]} – {IssueDescription[type]}</option>
                {/each}
            </SelectControl>
            {#if $errors.type}
                <p class="error">Select what kind of feedback</p>
            {/if}
            <!-- {#each $errors.type ?? [] as error}
                <p class="error">{error}</p>
            {/each} -->
        </TuiContentSection>

        <hr />

        <!-- ! Issue Title + Content -->
        <TuiContentSection>
            <h2>{$form.type ? IssueTitle[$form.type] : "Feedback"}</h2>

            <Input
                name="content"
                placeholder="Title"
                bind:value={$form.title}
                invalid={!!$errors.title}
            />
            {#each $errors.title ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <TextArea
                name="content"
                placeholder={$form.type ? IssuePrompt[$form.type] : "Description"}
                bind:value={$form.content}
                invalid={!!$errors.content}
                rows={10}
            />
            {#each $errors.content ?? [] as error}
                <p class="error">{error}</p>
            {/each}
        </TuiContentSection>

        <hr />

        <!-- ! File List -->
        <TuiContentSection>
            <h2>Attached Files</h2>
            {#if files.length > 0}
                {#each files as file, i}
                    {@const isFileTooLarge = file.size > FILE_MAX_SIZE}
                    <div class="item item-p">
                        <div class="flex items-center justify-between gap-4">
                            <div class="flex items-center gap-4 truncate">
                                <div class="flex flex-col truncate pl-1">
                                    <p class="truncate text-sm">{file.name}</p>
                                    <div class="flex items-center text-xs text-step-500">
                                        <p class={isFileTooLarge ? "text-red-000" : ""}>
                                            {bytesToHumanString(file.size)}
                                        </p>
                                        {#if isFileTooLarge}
                                            <p>&nbsp;– max. {FILE_MAX_SIZE_STRING}</p>
                                        {/if}
                                    </div>
                                </div>
                            </div>
                            <ButtonIcon
                                color="transparent"
                                iconSize="sm"
                                icon={trash3_fill}
                                on:click={() => removeFile(i)}
                            />
                        </div>
                    </div>
                {/each}
            {:else}
                <div class="item item-p">
                    <div class="flex flex-col items-center gap-4 py-4">
                        <p class="text-center text-xs text-step-500">
                            Please attach a screenshot if possible.
                        </p>
                    </div>
                </div>
            {/if}
            <Button
                thin
                color={dragover ? "blue" : "gray"}
                icon={paperclip}
                on:click={() => {
                    const input = document.createElement("input")
                    input.type = "file"
                    input.multiple = true
                    input.onchange = onAttachImage
                    input.click()
                }}
            >
                Attach File
            </Button>
            <p>You can drag and drop a file here.</p>
        </TuiContentSection>

        <!-- ! Error -->
        {#if $errors._errors}
            <TuiContentSection>
                {#each $errors._errors as error}
                    <p class="error">{error}</p>
                {/each}
            </TuiContentSection>
        {/if}

        <hr />

        <!-- ! Submit -->
        <TuiContentSection>
            <Button
                color="blue"
                type="submit"
                disabled={uploading}
                icon={uploading ? cloud_arrow_up_fill : null}
            >
                {uploading ? "Uploading..." : "Send"}
            </Button>
        </TuiContentSection>
    </TuiContent>
</form>
