<script lang="ts">
    import { privacy, terms } from "$docs/legals"
    import api from "$lib/api"
    import { auth } from "$lib/model/Auth"
    import { SCHEMA, validatorFromZod } from "$lib/validate"
    import { Subject, debounceTime, switchMap } from "rxjs"
    import { getContext } from "svelte"
    import {
        setError,
        stringProxy,
        superForm,
        superValidateSync,
    } from "sveltekit-superforms/client"
    import { z } from "zod"

    import Image from "$components/Image.svelte"
    import ModalFullscreenDocs from "$components/ModalFullscreenDocs.svelte"
    import SubLink from "$components/SubLink.svelte"
    import ModalImageSelection from "$components/modal/ModalImageSelection.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Button from "$components/tui/Button.svelte"
    import Checkbox from "$components/tui/Checkbox.svelte"
    import TuiContent from "$components/tui/TuiContent.svelte"
    import TuiContentSection from "$components/tui/TuiContentSection.svelte"
    import Input from "$components/tui/Input/Input.svelte"
    import InputPassword from "$components/tui/Input/InputPassword.svelte"
    import posthog from "posthog-js"
    import lang from "$lang"

    let modalVC = getContext<IModalVC>("ModalVC")

    /* ========================================= Exports ======================================== */

    export let onSuccess: (() => void) | null = null

    export let email: string = ""
    export let password: string = ""

    /* ========================================================================================== */

    const formSchema = z.object({
        ...SCHEMA.register,
        ...SCHEMA.account,
    })

    // Using `superValidateSync` instead of `superValidate` because we are top-level and cannot run `await`
    const formValidate = superValidateSync(
        // Initial field values
        {
            email,
            password,
        },
        // The Zod object schema
        formSchema,
        // Options
        {
            // Add initial errors to all fields that are not validated successfully
            errors: false,
            // Needs to be specified if there are ever two forms with the same schema
            id: undefined,
        },
    )

    const {
        // A store of the form data
        form,
        // A store of all validation errors of fields
        errors,
        // A store listing the constraints for each field, can be applied to `<input>` elements via `{...$constraints.field}`
        constraints,
        // A store listing the tainted (dirty) form fields
        tainted,
        // A directive to improve the `<form>` element (required for SPA mode)
        enhance,
    } = superForm(formValidate, {
        // Use SPA mode (use `validators` instead of sending to server + run `onUpdate` on success)
        SPA: true,
        // Best is `auto` (it validates on input when there was an error and validates on blur otherwise)
        validationMethod: "auto",
        // For client side validation (Zod object schema), if omitted no client side validation is performed
        validators: {
            email: validatorFromZod(SCHEMA.register.email),
            password: validatorFromZod(SCHEMA.register.password),
            name: validatorFromZod(SCHEMA.account.name),
            image: validatorFromZod(SCHEMA.account.image),
        },
        // Reset the form upon a successful result
        resetForm: true,
        // Do not trigger load functions
        invalidateAll: false,
        // Allow any data in the form, not only strings or numbers
        dataType: "json",

        // Because we are in SPA mode, we use `onUpdate`
        onUpdate: async ({ form }) => {
            // Check that all fields have been validated
            if (form.valid) {
                console.log("[Form] submit()")

                // Seems like this is not (/ no longer) necessary
                // Blur all input elements to validate them
                // ;(document.activeElement as HTMLElement | null)?.blur()

                // Check conditions not checked by `validators`
                let validUsername = await SCHEMA.account.username.safeParseAsync(form.data.username)
                if (!validUsername.success) {
                    setError(
                        form,
                        "username",
                        validUsername.error.issues.map((issue) => issue.message),
                    )

                    return
                }

                if (!acceptLegal) {
                    setError(form, "Accept Terms of Use and Privacy Policy")

                    return
                }

                // Do submit action
                const result = await auth.register({
                    login: {
                        email: form.data.email,
                        password: form.data.password,
                    },
                    account: {
                        username: form.data.username,
                        name: form.data.name,
                        image: form.data.image,
                    },
                })

                // Handle form submit errors
                if (!result.ok) {
                    if (result.error.type === "EmailTaken") {
                        setError(form, "email", "Email address already registered")
                    } else if (result.error.type === "UsernameTaken") {
                        setError(form, "username", "Username already used")
                    } else if (result.error.type === "Check") {
                        setError(form, "Form data invalid")
                    } else if (result.error.type === "Internal") {
                        setError(form, "Internal Server Error")
                    } else if (result.error.type === "Network") {
                        setError(form, "Network Error")
                    } else if (result.error.type === "InvalidResponse") {
                        setError(form, "Unknown Error")
                    }

                    return
                }

                // Successfully submitted
                if (onSuccess) onSuccess()

                posthog.capture("register")
            }
        },
    })

    /* ========================================================================================== */

    let acceptLegal = false

    /* ================================ Username Free Validation ================================ */

    const username$ = new Subject<string>()
    stringProxy(form, "username", { empty: "null" }).subscribe((username) =>
        username$.next(username),
    )
    username$
        .pipe(
            debounceTime(500),
            switchMap(async (username) => {
                let parsed = SCHEMA.account.username.safeParse(username)
                if (!parsed.success) {
                    return parsed.error.issues.map((issue) => issue.message)
                }

                let result = await api.account.is_username_free({ username })
                if (result.ok) {
                    if (!result.value) {
                        return ["Username already used"]
                    }
                } else {
                    if (result.error.type === "Internal") {
                        return ["Internal Server Error"]
                    } else if (result.error.type === "Network") {
                        return ["Network Error"]
                    } else if (result.error.type === "InvalidResponse") {
                        return ["Unknown Error"]
                    }
                }
            }),
        )
        .subscribe((errorArray) => {
            $errors.username = errorArray
        })

    /* ====================================== Image Select ====================================== */

    let userImages: string[] = []
    api.assets.images_user().then((images) => {
        if (images.ok) {
            userImages = images.value

            // Set random user image if required
            if (!$form.image) {
                const image = userImages[Math.floor(Math.random() * userImages.length)]
                $form.image = image
            }
        }
    })

    function selectImage(selection: string) {
        console.log("selectImage", selection)
        $form.image = selection
    }

    /* ========================================================================================== */

    function showTerms() {
        modalVC.push({
            component: ModalFullscreenDocs,
            props: {
                title: $lang.register.Terms_Of_Use(),
                data: terms.article.content_html,
            },
        })
    }

    function showPrivacy() {
        modalVC.push({
            component: ModalFullscreenDocs,
            props: {
                title: $lang.register.Privacy_Policy(),
                data: privacy.article.content_html,
            },
        })
    }
</script>

<form method="POST" use:enhance>
    <TuiContent>
        <TuiContentSection>
            <h2>{$lang.register.Your_Login()}</h2>

            <!-- ! Email -->
            <Input
                name="email"
                type="email"
                placeholder="steve@gmail.com"
                bind:value={$form.email}
                invalid={!!$errors.email}
                autocomplete="username"
            />
            {#each $errors.email ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <!-- ! Password -->
            <InputPassword
                name="password"
                placeholder="password"
                bind:value={$form.password}
                invalid={!!$errors.password}
                autocomplete="new-password"
            />
            {#each $errors.password ?? [] as error}
                <p class="error">{error}</p>
            {/each}
        </TuiContentSection>

        <hr />

        <TuiContentSection>
            <h2>{$lang.register.Your_Profile()}</h2>

            <!-- ! Name -->
            <Input
                name="name"
                placeholder="Steve"
                bind:value={$form.name}
                invalid={!!$errors.name}
                autocomplete="given-name"
            />
            {#each $errors.name ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <!-- ! Username -->
            <Input
                name="username"
                prefix="@"
                placeholder="steve_123"
                bind:value={$form.username}
                invalid={!!$errors.username}
                autocomplete="nickname"
            />
            {#each $errors.username ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <!-- ! Image -->
            <SubLink
                class="py-[6px] pl-[6px]"
                component={ModalImageSelection}
                props={{
                    cols: 3,
                    colsLg: 5,
                    roundedFull: true,
                    images: userImages,
                    select: selectImage,
                    selected: $form.image,
                }}
            >
                <div class="flex items-center gap-4">
                    <div
                        class="h-[60px] w-[60px] shrink-0 overflow-hidden rounded-full border border-step-150"
                    >
                        <Image image={$form.image} size={128} />
                    </div>
                    <p class="text-step-600">{$lang.Select_Image()}</p>
                </div>
            </SubLink>

            <p>{$lang.register.Profile_Confidence()}</p>
        </TuiContentSection>

        <hr />

        <!-- ! Accept Legal -->
        <TuiContentSection>
            <div class="flex items-center gap-3 px-1">
                <Checkbox bind:value={acceptLegal}></Checkbox>
                <p class="text-center- text-xs text-step-500">
                    {$lang.register.I_Accept()}
                    <button
                        on:click|preventDefault={showTerms}
                        class="underline underline-offset-2"
                        type="button"
                    >
                        {$lang.register.Terms_Of_Use()}
                    </button>
                    {$lang.register.And()}
                    <button
                        on:click|preventDefault={showPrivacy}
                        class="underline underline-offset-2"
                        type="button"
                    >
                        {$lang.register.Privacy_Policy()}
                    </button>
                </p>
            </div>
        </TuiContentSection>

        <!-- ! General Errors -->
        {#if $errors._errors}
            <TuiContentSection>
                {#each $errors._errors as error}
                    <p class="error">{error}</p>
                {/each}
            </TuiContentSection>
        {/if}

        <!-- ! Submit Button -->
        <TuiContentSection>
            <Button type="submit" color="green">
                {$lang.menu.Create_Account()}
            </Button>
        </TuiContentSection>
    </TuiContent>
</form>
