<script lang="ts">
    import ModalFullscreenDocs from "$components/ModalFullscreenDocs.svelte"
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import { cookies, imprint, licenses, privacy, terms } from "$docs/legals"
    import SubLink from "../SubLink.svelte"
    import Header from "./Header.svelte"

    export let hidden = false
</script>

<ModalFullscreen>
    <Header slot="head" title="Legal" escBack={!hidden} />

    <div class="buttons-col card-p">
        <SubLink
            text="Cookie Policy"
            component={ModalFullscreenDocs}
            props={{
                title: "Cookie Policy",
                data: cookies.article.content_html,
            }}
        />
        <SubLink
            text="Imprint"
            component={ModalFullscreenDocs}
            props={{
                title: "Imprint",
                data: imprint.article.content_html,
            }}
        />
        <SubLink
            text="Terms of Use"
            component={ModalFullscreenDocs}
            props={{
                title: "Terms of Use",
                data: terms.article.content_html,
            }}
        />
        <SubLink
            text="Privacy Policy"
            component={ModalFullscreenDocs}
            props={{
                title: "Privacy Policy",
                data: privacy.article.content_html,
            }}
        />
        <SubLink
            text="Licenses"
            component={ModalFullscreenDocs}
            props={{
                title: "Licenses",
                data: licenses.article.content_html,
            }}
        />
    </div>
</ModalFullscreen>
