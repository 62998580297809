<script lang="ts" context="module">
    const MAX_WIDTH = {
        xs: "sm:max-w-xs",
        sm: "sm:max-w-sm",
        md: "sm:max-w-md",
        lg: "sm:max-w-lg",
        xl: "sm:max-w-xl",
    }
</script>

<script lang="ts">
    import Modal from "./Modal.svelte"

    let className = ""
    export { className as class }

    export let maxWidth: "xs" | "sm" | "md" | "lg" | "xl" = "xs"
</script>

<Modal>
    <div
        id="container"
        class="flex
               w-full flex-col
               bg-step-000 pt-safe
               sm:card
               {MAX_WIDTH[maxWidth]} sm:pt-[unset] {className}"
        on:touchmove|stopPropagation={() => {}}
    >
        {#if $$slots.head}
            <div
                class="flex min-h-[72px] flex-col justify-center border-b
                       border-step-050 px-2 sm:px-6"
            >
                <slot name="head" />
            </div>
        {/if}
        <div class="grow overflow-y-auto overscroll-y-contain">
            <div class="min-h-overscroll pb-safe sm:pb-[unset]">
                <slot />
            </div>
        </div>
    </div>
</Modal>

<style lang="postcss">
    #container {
        @apply h-[100svh] sm:h-auto;
    }
    @media (display-mode: fullscreen), (display-mode: standalone) {
        #container {
            @apply h-[100lvh] sm:h-auto;
        }
    }
</style>
