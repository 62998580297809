<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import ModalOk from "$components/modal/ModalOk.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Header from "$components/settings/Header.svelte"
    import type { SCHEMA } from "$lib/validate"
    import { getContext } from "svelte"
    import type { z } from "zod"
    import FormIssue from "./FormIssue.svelte"
    import lang from "$lang"

    let modalVC = getContext<IModalVC>("ModalVC")

    export let type: z.infer<typeof SCHEMA.issue.type> | undefined = undefined
</script>

<ModalFullscreen maxWidth="lg">
    <Header slot="head" title="Feedback" backText={$lang.Cancel()} escBack={false} />

    <div class="p-6">
        <FormIssue
            {type}
            onSuccess={(type) => {
                modalVC.pop()
                if (type === "Help") {
                    modalVC.push({
                        component: ModalOk,
                        props: {
                            title: "Support Requested",
                            text: "We will come back to you shortly via email.",
                        },
                    })
                } else {
                    modalVC.push({
                        component: ModalOk,
                        props: {
                            title: "Thank you!",
                            text: "Thank you very much for helping to improve GroupTube!",
                        },
                    })
                }
            }}
        />
    </div>
</ModalFullscreen>
