<script lang="ts">
    import ModalConfirmCancel from "$components/modal/ModalConfirmCancel.svelte"
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Button from "$components/tui/Button.svelte"
    import { auth } from "$lib/model/Auth"
    import posthog from "posthog-js"
    import { getContext } from "svelte"
    import Header from "./Header.svelte"
    import SettingsChangePassword from "./SettingsChangePassword.svelte"
    import SettingsRequestChangeEmail from "./SettingsRequestChangeEmail.svelte"
    import FormAccountUpdate from "./auth/FormAccountUpdate.svelte"
    import lang from "$lang"

    const modalVC = getContext<IModalVC>("ModalVC")

    const { auth$ } = auth

    /* ========================================================================================== */

    function clickChangeEmail() {
        modalVC.push({
            component: SettingsRequestChangeEmail,
            props: {},
        })
    }

    function clickChangePassword() {
        modalVC.push({
            component: SettingsChangePassword,
            props: {},
        })
    }

    function clickDeleteAccount() {}

    function clickRequestData() {}

    function clickLogOut() {
        modalVC.push({
            component: ModalConfirmCancel,
            props: {
                title: $lang.menu.Log_Out(),
                text: $lang.menu.Log_Out_DESC(),
                confirmText: $lang.menu.Log_Out(),
                confirmColor: "orange",
                confirmAction: () => {
                    posthog.capture("logout")

                    auth.logout()
                    modalVC.pop()
                },
            },
        })
    }

    export let hidden = false
</script>

<ModalFullscreen>
    <Header slot="head" title="Account" escBack={!hidden} />

    <div class="settings">
        <FormAccountUpdate />

        <hr />

        <div>
            <p class="whitespace-pre-line text-center">
                {$lang.Email_Address()}:<br />{$auth$?.email}
            </p>

            {#if !$auth$?.email_verified}
                <p class="text-center !text-orange-000">
                    {$lang.Email_not_verified_DESC()}
                </p>
            {/if}

            <div class="buttons-col">
                <Button thin color="gray" colorHover="orange" on:click={clickChangeEmail}>
                    Change Email
                </Button>
                <Button thin color="gray" colorHover="orange" on:click={clickChangePassword}>
                    Change Password
                </Button>
                <Button thin color="gray" on:click={clickRequestData} disabled>Request Data</Button>
                <Button thin color="gray" colorHover="red" on:click={clickDeleteAccount} disabled>
                    Delete Account
                </Button>
                <Button thin color="gray" colorHover="orange" on:click={clickLogOut}>
                    {$lang.menu.Log_Out()}
                </Button>
            </div>
        </div>
        <!-- <hr />
        <div>
        </div> -->
    </div>
</ModalFullscreen>
