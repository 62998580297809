import { IssueTypes } from "$components/issue/issue"
import type { Validator } from "sveltekit-superforms"
import { z } from "zod"

const accountUsernameRegex = /^[a-zA-Z0-9_.]{3,25}$/
const accountNameRegex = /^\S.{1,38}\S$/

const groupNameRegex = /^\S.{1,38}\S$/

export const SCHEMA = {
    register: {
        email: z.string().trim().email(),
        password: z.string().trim().min(8),
    },

    login: {
        email: z.string().trim().email(),
        password: z.string().trim().min(8),
    },

    account: {
        username: z
            .string()
            .trim()
            .regex(
                accountUsernameRegex,
                "Must be between 3 and 25 characters long. Only letters, numbers, underscores and dots.",
            ),
        name: z.string().trim().regex(accountNameRegex, "Must be between 3 and 40 characters"),
        image: z.string().trim(),
    },

    group: {
        name: z.string().trim().regex(groupNameRegex, "Must be between 3 and 40 characters"),
        description: z.string().trim().max(200),
        image: z.string().trim(),
    },

    issue: {
        type: z.enum(IssueTypes),
        title: z.string().trim().min(3).max(100),
        content: z.string().trim().min(20).max(1000),
    },
}

export function validatorFromZod<T>(zod: z.ZodType<T>): Validator<T> {
    return (value: T) => {
        const result = zod.safeParse(value)
        if (!result.success) {
            return result.error.issues.map((issue) => issue.message)
        }
    }
}
