<script lang="ts">
    const COLS = {
        1: "grid-cols-1",
        2: "grid-cols-2",
        3: "grid-cols-3",
        4: "grid-cols-4",
        5: "grid-cols-5",
        6: "grid-cols-6",
        7: "grid-cols-7",
        8: "grid-cols-8",
        9: "grid-cols-9",
    }
    const COLS_LG = {
        1: "lg:grid-cols-1",
        2: "lg:grid-cols-2",
        3: "lg:grid-cols-3",
        4: "lg:grid-cols-4",
        5: "lg:grid-cols-5",
        6: "lg:grid-cols-6",
        7: "lg:grid-cols-7",
        8: "lg:grid-cols-8",
        9: "lg:grid-cols-9",
    }

    // ====================================================================================================

    import Image from "./Image.svelte"

    export let images: string[]
    export let onSelect: (iamge: string) => void

    export let cols: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 3
    export let colsLg: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null = null
    export let roundedFull = false
    export let selected: string | null
</script>

<div class="grid {COLS[cols]} {colsLg ? COLS_LG[colsLg] : ''} gap-3 p-6">
    {#each images as image}
        <Image
            {image}
            size={128}
            class="item aspect-square cursor-pointer
                   {roundedFull ? 'rounded-full' : ''}
                   {selected === image ? 'outline outline-2 outline-blue-000' : ''}"
            on:click={() => {
                selected = image
                onSelect(image)
            }}
            loading="lazy"
        />
    {/each}
</div>
