<script lang="ts">
    import lang from "$lang"
    import type { UserData } from "$lib/model/IUser"

    export let user: UserData
    export let showYou: boolean = false
    // export let layout: 'vertical' | 'horizontal' = 'vertical'
    export let size: "normal" | "tiny" = "normal"
    export let secondaryColor: "gray" | "normal" = "gray"
    export let selectable: boolean = true

    export let textClass: string = ""
</script>

<div class="flex items-center truncate {size === 'normal' ? 'gap-2' : 'gap-1'}">
    <p
        class="{selectable ? 'select-text' : ''} truncate font-semibold
        {size === 'normal' ? 'text-base' : 'text-xs'}
        {textClass}"
    >
        {user.name}
    </p>

    {#if showYou}
        <span
            class="shrink-0 truncate
            {size === 'normal' ? 'text-sm' : 'text-xs'}
            {secondaryColor === 'gray' ? 'text-step-500' : 'text-step-600'}
            {textClass}"
        >
            ({$lang.lower_case.you()})
        </span>
    {/if}
</div>
