<script lang="ts">
    // ====================================================================================================

    export let value: string
    export let placeholder: string = ""
    export let rows: number = 5

    export let rounded: string = "12px"

    export let disabled: boolean = false
    export let invalid: boolean = false

    export let name: string = ""
    export let type = "text"
    export let autocomplete: string = ""
    export let maxLength: number | null = null

    let focus = false
</script>

<div
    style:border-radius={rounded}
    class="relative flex overflow-hidden border border-step-150 bg-step-100
    {invalid ? 'outline outline-1 outline-red-000' : ''}
    {focus ? 'outline !outline-2 outline-blue-000' : ''}
    {disabled ? 'pointer-events-none opacity-50' : ''}
    "
>
    <textarea
        bind:value
        {placeholder}
        class="min-w-0 grow resize-none bg-[transparent] px-2 py-1 placeholder-step-500 outline-none"
        on:focus={() => (focus = true)}
        on:blur={() => (focus = false)}
        {rows}
        {...{ type }}
        {autocomplete}
        {name}
        {disabled}
        aria-invalid={invalid}
    />

    <!-- WARNING: Works, but can overlap when text is behind this -->
    {#if maxLength !== null}
        <div
            class="absolute bottom-1.5 {value.split('\n').length <= rows ? 'right-1.5' : 'right-4'}"
        >
            <p class="text-xs {value.length <= maxLength ? 'text-step-500' : 'text-red-000'}">
                {value.length} / {maxLength}
            </p>
        </div>
    {/if}
</div>
