<script lang="ts">
    import { afterNavigate } from "$app/navigation"

    // feat: Reset scroll after navigation (otherwise when navigating when scrolled down, it stays at that scroll position)
    let scrollContainer: HTMLElement
    afterNavigate(() => {
        scrollContainer.scroll({ top: 0, left: 0 })
    })
</script>

<!--
    @component
    This component provides a layout with optional NavBar and Footer.
    It will provide left, right and bottom safearea. But the top safearea has to be implemented by the NavBar or main slot.
-->

<main class="flex h-full w-full flex-col">
    <slot name="nav" />

    <div class="grow overflow-y-auto overscroll-y-contain bg-step-base" bind:this={scrollContainer}>
        <div class="min-h-overscroll dotted-bg flex flex-col px-safe pb-safe">
            <div class="flex grow flex-col">
                <slot />
            </div>

            <slot name="footer" />
        </div>
    </div>
</main>
