<script lang="ts">
    // ====================================================================================================

    import ImageSelection from "$components/ImageSelection.svelte"
    import Header from "$components/settings/Header.svelte"
    import ModalFullscreen from "./ModalFullscreen.svelte"
    import lang from "$lang"

    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import { getContext } from "svelte"
    let modalVC = getContext<IModalVC>("ModalVC")

    export let images: string[]
    export let select: (image: string) => void
    export let cols: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 = 3
    export let colsLg: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | null = null
    export let roundedFull = false
    export let selected: string | null

    function _select(selection: string) {
        modalVC.pop()
        select(selection)
    }

    export let hidden = false
</script>

<ModalFullscreen class="lg:max-w-xl">
    <Header slot="head" title={$lang.Select_Image()} escBack={!hidden} />

    <ImageSelection {images} onSelect={_select} {cols} {colsLg} {roundedFull} {selected} />
</ModalFullscreen>
