<script lang="ts">
    import { locale } from "$i18n/i18n-svelte"
    import { locales } from "$i18n/i18n-util"
    import { dynamicSwitchLocale } from "$lib/settings/lang"
    import { globe2 } from "@timephy/tui-icons-svelte"
    import SelectControl from "./tui/SelectControl.svelte"

    let className = ""
    export { className as class }

    function onChange(event: any) {
        dynamicSwitchLocale(event.target.value)
    }
</script>

<SelectControl
    tiny
    value={$locale}
    icon={globe2}
    class="text-step-500 [&>select]:text-step-600 {className}"
    on:change={onChange}
>
    {#each locales as _locale}
        <!-- for some reason `selected` has to be given and is not correctly calculated based on `value` during initial SSR -->
        <option value={_locale} label={_locale.toUpperCase()} selected={_locale === $locale} />
    {/each}
</SelectControl>
