<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import SwitchLabel from "$components/SwitchLabel.svelte"
    import Button from "$components/tui/Button.svelte"
    import { tutorialsHidden$, tutorialsHideAll$ } from "$lib/settings/local"
    import { arrow_counterclockwise } from "@timephy/tui-icons-svelte"
    import Header from "./Header.svelte"
    import lang from "$lang"

    // @ts-expect-error
    tutorialsHideAll$.set = tutorialsHideAll$.next

    export let hidden = false
</script>

<ModalFullscreen>
    <Header slot="head" title={$lang.menu.Tutorials()} escBack={!hidden} />

    <div class="settings">
        <div>
            <h2>{$lang.menu.Tutorials()}</h2>
            <SwitchLabel label="Hide All" bind:value={$tutorialsHideAll$} class="px-[6px]" />
            <p>Whether to show tutorials for GroupTube's features.</p>
        </div>
        <div>
            <Button
                thin
                icon={arrow_counterclockwise}
                color="gray"
                colorHover="blue"
                on:click={() => {
                    tutorialsHidden$.next(new Set())
                    tutorialsHideAll$.next(false)
                }}
                disabled={$tutorialsHidden$.size === 0 && !$tutorialsHideAll$}
            >
                Reset Hidden Tutorials
            </Button>
        </div>
    </div>
</ModalFullscreen>
