<script lang="ts" context="module">
    export type Size = "tiny" | "normal" | "large"

    const SIZE: { [size in Size]: string } = {
        tiny: "40px",
        normal: "48px",
        large: "56px",
    }

    const BLUE_BORDER_CONTAINER = {
        0: "",
        1: "border-[1px] !border-blue-000",
        2: "border-[2px] !border-blue-000",
        3: "border-[3px] !border-blue-000",
        4: "border-[4px] !border-blue-000",
    }
    const BLUE_BORDER_IMAGE = {
        0: "",
        1: "",
        2: "-m-[1px]",
        3: "-m-[2px]",
        4: "-m-[3px]",
    }
</script>

<script lang="ts">
    import Image from "$components/Image.svelte"

    let className = ""
    export { className as class }

    export let blueBorderWidth: 0 | 1 | 2 | 3 | 4 = 0

    export let user: { name: string; image?: string | null }
    export let size: Size
    $: userInitials = user.name
        .split(" ")
        .map((s) => s.substring(0, 1))
        .join("")
        .substring(0, 2)
        .toUpperCase()
</script>

<div
    class="flex items-center justify-center overflow-hidden rounded-full
            border-[1px] border-step-150
            bg-step-100 text-step-500
            {BLUE_BORDER_CONTAINER[blueBorderWidth]}
            {className}"
    style:width={SIZE[size]}
    style:height={SIZE[size]}
    style:min-width={SIZE[size]}
    style:min-height={SIZE[size]}
>
    {#if user.image}
        <Image image={user.image} alt="User Profile" class={BLUE_BORDER_IMAGE[blueBorderWidth]} />
    {:else}
        <p>{userInitials}</p>
    {/if}
</div>
