<script lang="ts">
    export let value: boolean
    export let onChange: null | ((value: boolean) => void) = null
    export let disabled: boolean = false
</script>

<button
    class="
        btn
        {value ? `btn-green` : `btn-gray`}
        relative h-7 w-12 rounded-full
        duration-200
    "
    on:click={() => {
        if (onChange) {
            onChange(!value)
        } else {
            value = !value
        }
    }}
    {disabled}
>
    <div
        class="absolute bottom-[1px] left-[1px] h-6 w-6 rounded-full bg-white
            duration-200 {value ? `translate-x-5` : ``}"
    ></div>
</button>
