<script lang="ts" context="module">
    const ICON = {
        system: gear_wide_connected,
        light: sun_fill,
        dark: moon_fill,
    }
</script>

<script lang="ts">
    import ModalFullscreen from "$components/modal/ModalFullscreen.svelte"
    import SelectControl from "$components/tui/SelectControl.svelte"
    import { lightDarkMode$ } from "$lib/settings/local"
    import { gear_wide_connected, moon_fill, sun_fill } from "@timephy/tui-icons-svelte"
    import Header from "./Header.svelte"
    import lang from "$lang"

    // @ts-expect-error
    lightDarkMode$.set = lightDarkMode$.next

    export let hidden = false
</script>

<ModalFullscreen>
    <Header slot="head" title={$lang.menu.Appearance()} escBack={!hidden} />

    <div class="settings">
        <div>
            <h2>Theme</h2>
            <SelectControl bind:value={$lightDarkMode$} icon={ICON[$lightDarkMode$]}>
                <option value="system">Use System Setting</option>
                <option value="light">Light Mode</option>
                <option value="dark">Dark Mode</option>
            </SelectControl>
            <p>How the user interface looks like.</p>
        </div>
    </div>
</ModalFullscreen>
