<script lang="ts">
    import type { UserData } from "$lib/model/IUser"
    import type { Size } from "./UserImage.svelte"
    import UserImage from "./UserImage.svelte"
    import UserName from "./UserName.svelte"

    export let user: UserData
    export let showYou: boolean = false
    export let detail: string | null = null

    export let imageSize: Size
    export let imageBlueBorderWidth: 0 | 1 | 2 | 3 | 4 = 0
</script>

<div class="flex items-center gap-[14px] truncate">
    <UserImage {user} size={imageSize} blueBorderWidth={imageBlueBorderWidth} />
    <div class="flex flex-col truncate">
        <UserName {user} {showYou} />
        {#if detail}
            <p class="truncate text-left text-xs text-step-500">{detail}</p>
        {/if}
    </div>
</div>
