<script lang="ts">
    import type { IModalVC } from "$components/modal/ModalVC.svelte"
    import Button from "$components/tui/Button.svelte"
    import TuiContent from "$tui/TuiContent.svelte"
    import TuiContentSection from "$tui/TuiContentSection.svelte"
    import Input from "$components/tui/Input/Input.svelte"
    import InputPassword from "$components/tui/Input/InputPassword.svelte"
    import { z } from "zod"
    import SettingsRegister from "../SettingsRegister.svelte"

    import { auth } from "$lib/model/Auth"
    import { SCHEMA } from "$lib/validate"
    import posthog from "posthog-js"
    import { getContext } from "svelte"
    import { setError, superForm, superValidateSync } from "sveltekit-superforms/client"
    import SettingsForgotPassword from "../SettingsRequestResetPassword.svelte"
    import Notification from "$components/Notification.svelte"
    import { BehaviorSubject } from "rxjs"
    import lang from "$lang"

    let modalVC = getContext<IModalVC>("ModalVC")

    /* ========================================================================================== */

    export let fromWithinGroup = false

    let showForgotPassword = false

    export let onSuccess: (() => void) | null = null

    /* ========================================== Form ========================================== */

    const formSchema = z.object({
        ...SCHEMA.login,
    })

    // Using `superValidateSync` instead of `superValidate` because we are top-level and cannot run `await`
    const formValidate = superValidateSync(
        // Initial field values
        {},
        // The Zod object schema
        formSchema,
        // Options
        {
            // Add initial errors to all fields that are not validated successfully
            errors: false,
            // Needs to be specified if there are ever two forms with the same schema
            id: undefined,
        },
    )

    const {
        // A store of the form data
        form,
        // A store of all validation errors of fields
        errors,
        // A store listing the constraints for each field, can be applied to `<input>` elements via `{...$constraints.field}`
        constraints,
        // A directive to improve the `<form>` element (required for SPA mode)
        enhance,
    } = superForm(formValidate, {
        // Use SPA mode (use `validators` instead of sending to server + run `onUpdate` on success)
        SPA: true,
        // Best is `auto` (it validates on input when there was an error and validates on blur otherwise)
        validationMethod: "auto",
        // For client side validation (Zod object schema), if omitted no client side validation is performed
        validators: formSchema,
        // Reset the form upon a successful result
        resetForm: true,
        // Do not trigger load functions
        invalidateAll: false,
        // Allow any data in the form, not only strings or numbers
        dataType: "json",

        // Because we are in SPA mode, we use `onUpdate`
        onUpdate: async ({ form }) => {
            // Check that all fields have been validated
            if (form.valid) {
                console.log("[Form] submit()")

                // Seems like this is not (/ no longer) necessary
                // Blur all input elements to validate them
                // ;(document.activeElement as HTMLElement | null)?.blur()

                // Check conditions not checked by `validators`
                // if (!acceptLegal) {
                //     setError(form, "Accept Terms of Use and Privacy Policy")
                //     return
                // }

                // Do for submit action
                const result = await auth.login(form.data)

                // Handle form submit errors
                if (!result.ok) {
                    if (result.error.type === "EmailNotFound") {
                        setError(form, "email", "Email address not registered")
                    } else if (result.error.type === "PasswordWrong") {
                        showForgotPassword = true
                        setError(form, "password", "Wrong password")
                    } else if (result.error.type === "Check") {
                        setError(form, result.error.content)
                    } else if (result.error.type === "Internal") {
                        setError(form, "Internal Server Error")
                    } else if (result.error.type === "Network") {
                        setError(form, "Network Error")
                    } else if (result.error.type === "InvalidResponse") {
                        setError(form, "Unknown Error")
                    }

                    return
                }

                // Successfully submitted
                if (onSuccess) onSuccess()

                posthog.capture("login")
            }
        },
    })

    /* ========================================================================================== */

    function clickCreateAccount() {
        modalVC.push({
            component: SettingsRegister,
            props: {
                email: $form.email,
                password: $form.password,
            },
        })
    }

    function clickForgotPassword() {
        modalVC.push({
            component: SettingsForgotPassword,
            props: {
                email: $form.email,
            },
        })
    }
</script>

<!-- `method="POST"` is required for `use:enhance` to work (even in SPA mode) -->
<form method="POST" use:enhance>
    <TuiContent>
        <TuiContentSection>
            <h1 class="relative">
                Login
                {#if fromWithinGroup}
                    <Notification
                        value$={new BehaviorSubject({ color: "red", content: "" })}
                        class="absolute right-0 top-[50%] -translate-y-[50%]"
                    />
                {/if}
            </h1>
            <p>{$lang.menu.Login_DESC()}</p>
        </TuiContentSection>

        <TuiContentSection>
            <Input
                name="email"
                type="email"
                placeholder="steve@gmail.com"
                bind:value={$form.email}
                invalid={!!$errors.email}
                autocomplete="username"
            />
            {#each $errors.email ?? [] as error}
                <p class="error">{error}</p>
            {/each}

            <InputPassword
                name="password"
                placeholder="password"
                bind:value={$form.password}
                invalid={!!$errors.password}
                autocomplete="current-password"
            />
            {#each $errors.password ?? [] as error}
                <p class="error">{error}</p>
            {/each}
        </TuiContentSection>

        {#if $errors._errors}
            <TuiContentSection>
                {#each $errors._errors as error}
                    <p class="error">{error}</p>
                {/each}
            </TuiContentSection>
        {/if}

        <TuiContentSection>
            <Button color="green" type="submit">Login</Button>

            <p class="text-center">{$lang.lower_case.or()}</p>

            <Button thin color="blue" on:click={clickCreateAccount}
                >{$lang.menu.Create_Account()}</Button
            >
            {#if showForgotPassword}
                <Button thin color="gray" on:click={clickForgotPassword}
                    >{$lang.menu.Forgot_Password()}</Button
                >
            {/if}
        </TuiContentSection>
    </TuiContent>
</form>
