<script lang="ts">
    let className = ""
    export { className as class }
</script>

<div class="flex flex-col gap-4 {className}">
    <slot />
</div>

<style lang="postcss">
    div > :global(hr) {
        @apply mx-1 my-1 border-step-100;
    }
</style>
